@import '~normalize.css';
@import 'swiper/css/bundle';
@import '../src/assets/css/iconfont.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    scroll-behavior: smooth;
}
body
{
    font-family : 'Noto Sans Sc';
    background  : #f1f1f1;

    & > .wpt-container
    {
        display : block;
        position: relative;
        width   : 100%;

        & > .wpt-content
        {
            display  : block;
            position : relative;
            width    : 100%;
        }
        & > .wpt-social
        {
            display           : block;
            position          : relative;
            width             : 100%;
            box-sizing        : border-box;
            -webkit-box-sizing: border-box;
            padding           : 0px 20px;
            z-index           : 9;

            & > .socialcover
            {
                display  : block;
                position : relative;
                max-width: 1100px;
                width    : 100%;
                margin   : 0 auto;

                & > .box
                {
                    display                : flex;
                    position               : absolute;
                    width                  : 100%;
                    backdrop-filter        : blur(10px);
                    -webkit-backdrop-filter: blur(10px);
                    background             : rgba(45,99,219,0.8);
                    box-sizing             : border-box;
                    -webkit-box-sizing     : border-box;
                    top                    : 50%;
                    transform              : translateY(-50%);
                    -webkit-transform      : translateY(-50%);
                    border-radius          : 20px;
                    -webkit-border-radius  : 20px;
                    box-sizing             : border-box;
                    -webkit-box-sizing     : border-box;
                    padding                : 26px 30px;
                    overflow               : hidden;

                    & > .text
                    {
                        display : block;
                        position: relative;
                        width   : 100%;

                        & > div
                        {
                            display       : block;
                            position      : relative;
                            width         : 100%;
                            font-size     : 30px;
                            line-height   : 38px;
                            color         : #ffffff;
                            text-transform: uppercase;
                            margin        : 0px 0px 4px 0px;

                            & > b
                            {
                                font-weight: 800;
                            }
                        }
                        & > span
                        {
                            display    : block;
                            position   : relative;
                            width      : 100%;
                            font-size  : 14px;
                            line-height: 20px;
                            color      : #ffffff;
                        }
                    }
                    & > .btn
                    {
                        display        : flex;
                        justify-content: center;
                        align-items    : center;
                        position       : relative;
                        margin         : 0px 0px 0px 40px;

                        & a
                        {
                            display           : inline-block;
                            position          : relative;
                            width             : 40px;
                            height            : 40px;
                            margin            : 0px 20px 0px 0px;
                            cursor            : pointer;
                            transition        : all 0.3s ease;
                            -webkit-transition: all 0.3s ease;
                            opacity           : 0.6;

                            &:hover
                            {
                                opacity:1;
                            }
                            &:last-child
                            {
                                margin:0px;
                            }
                            & > i
                            {
                                display    : block;
                                position   : absolute;
                                width      : 40px;
                                height     : 40px;
                                line-height: 40px;
                                font-size  : 32px;
                                text-align : center;
                                top        : calc(50% - 20px);
                                left       : calc(50% - 20px);
                                color      : #ffffff;
                            }
                        }
                    }
                }
            }
        }
        & > .wpt-download
        {
            display            : block;
            position           : relative;
            width              : 100%;
            box-sizing         : border-box;
            -webkit-box-sizing : border-box;
            padding            : 100px 20px 60px 20px;
            background-image   : url('assets/img/download-bg.png');
            background-repeat  : no-repeat;
            background-size    : cover;
            background-position: 50% 50%;
            z-index            : 1;

            & > .downloadcover
            {
                display  : block;
                position : relative;
                width    : 100%;
                max-width: 1100px;
                margin   : 0 auto;

                & > h1
                {
                    display    : block;
                    position   : relative;
                    width      : 100%;
                    text-align : center;
                    font-size  : 32px;
                    line-height: 42px;
                    font-weight: 600;
                    color      : #ffffff;
                    padding    : 0px;
                    margin     : 0px 0px 8px 0px;
                }
                & > h2
                {
                    display    : block;
                    position   : relative;
                    width      : 100%;
                    text-align : center;
                    font-size  : 20px;
                    line-height: 28px;
                    font-weight: normal;
                    color      : #ffffff;
                    padding    : 0px;
                    margin     : 0px 0px 30px 0px;
                }
                & > .summary
                {
                    display  : block;
                    position : relative;
                    width    : 100%;
                    max-width: 1100px;
                    margin   : 0 auto;

                    & > img
                    {
                        display : block;
                        position: relative;
                        float   : left;
                        width   : 600px;
                        z-index : 1;
                    }
                    & > .text
                    {
                        display        : flex;
                        justify-content: center;
                        align-items    : center;
                        position       : absolute;
                        width          : 510px;
                        height         : 100%;
                        top            : 0;
                        right          : 6px;

                        & > .textcover
                        {
                            display                : block;
                            position               : relative;
                            width                  : 100%;
                            box-sizing             : border-box;
                            -webkit-box-sizing     : border-box;
                            padding                : 50px 50px 80px 60px;
                            background             : rgba(255,255,255,0.05);
                            -webkit-backdrop-filter: blur(10px);
                            backdrop-filter        : blur(10px);
                            border                 : solid 1px rgba(255,255,255,0.2);
                            border-radius          : 0px 16px 16px 0px;
                            -webkit-border-radius  : 0px 16px 16px 0px;

                            & .sbox
                            {
                                display           : block;
                                position          : relative;
                                width             : 100%;
                                margin            : 38px 0px 0px 0px;
                                box-sizing        : border-box;
                                -webkit-box-sizing: border-box;
                                padding           : 0px 0px 0px 66px;

                                &:first-child
                                {
                                    margin:0px;
                                }
                                & > i
                                {
                                    display    : block;
                                    position   : absolute;
                                    width      : 40px;
                                    height     : 40px;
                                    line-height: 40px;
                                    font-size  : 38px;
                                    color      : #ffffff;
                                    top        : calc(50% - 20px);
                                    left       : 0px;
                                }
                                & > div
                                {
                                    display : block;
                                    position: relative;
                                    width   : 100%;

                                    & > p
                                    {
                                        display    : block;
                                        position   : relative;
                                        width      : 100%;
                                        font-size  : 16px;
                                        font-weight: 600;
                                        color      : #5da3ff;
                                        padding    : 0px;
                                        margin     : 0px 0px 8px 0px;
                                    }
                                    & > span
                                    {
                                        display    : block;
                                        position   : relative;
                                        width      : 100%;
                                        font-size  : 14px;
                                        line-height: 20px;
                                        color      : #bbbbbb;
                                        padding    : 0px;
                                        margin     : 0px;
                                    }
                                }
                            }
                            & > .button
                            {
                                display : block;
                                position: absolute;
                                width   : 100%;
                                height  : 84px;
                                bottom  : -42px;
                                left    : 0px;
                                text-align: center;

                                &.one
                                {
                                    & > .buttoncover
                                    {
                                        width:190px;

                                        & > .btn
                                        {
                                            & > .btncover
                                            {
                                                & .d
                                                {
                                                    width:100%;

                                                    & > div
                                                    {
                                                        display:none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                & > .buttoncover
                                {
                                    display : inline-block;
                                    position: relative;
                                    width   : 300px;
                                    height  : 84px;
                                    
                                    & > img
                                    {
                                        display : block;
                                        position: absolute;
                                        height  : 84px;
                                        top     : calc(50% - 42px);
                                        left    : 0px;
                                        z-index : 1;
                                    }
                                    & > .btn
                                    {
                                        display              : block;
                                        position             : absolute;
                                        background           : #005eda;
                                        width                : calc(100% - 64px);
                                        height               : 44px;
                                        top                  : calc(50% - 22px);
                                        right                : 0px;
                                        border-radius        : 8px;
                                        -webkit-border-radius: 8px;
                                        box-sizing           : border-box;
                                        -webkit-box-sizing   : border-box;
                                        padding              : 0px 10px 0px 24px;

                                        & > .btncover
                                        {
                                            display : block;
                                            position: relative;
                                            height  : 44px;
                                            width   : 100%;

                                            & .d
                                            {
                                                display           : block;
                                                position          : relative;
                                                float             : left;
                                                width             : calc(50% - 10px);
                                                height            : 44px;
                                                line-height       : 44px;
                                                cursor            : pointer;
                                                box-sizing        : border-box;
                                                -webkit-box-sizing: border-box;
                                                padding           : 0px 0px 0px 20px;
                                                font-size         : 14px;
                                                font-weight       : 600;
                                                margin            : 0px 20px 0px 0px;
                                                color             : #ffffff;

                                                &:last-child
                                                {
                                                    margin:0px;
                                                }
                                                & > i
                                                {
                                                    display  : block;
                                                    position : absolute;
                                                    width    : 20px;
                                                    height   : 20px;
                                                    top      : calc(50% - 10px);
                                                    left     : 0px;
                                                    font-size: 20px;
                                                    color    : #ffffff;
                                                }
                                                & > div
                                                {
                                                    display   : block;
                                                    position  : absolute;
                                                    width     : 1px;
                                                    height    : 12px;
                                                    top       : calc(50% - 6px);
                                                    background: rgba(255,255,255,0.2);
                                                    right     : -9px;
                                                }
                                            }
                                        }
                                        & > .refferal
                                        {
                                            display       : block;
                                            position      : absolute;
                                            width         : 100%;
                                            top           : 56px;
                                            left          : 0px;
                                            color         : #ffffff;
                                            text-transform: uppercase;

                                            & > span
                                            {
                                                display  : block;
                                                position : relative;
                                                width    : 100%;
                                                font-size: 12px;
                                                margin   : 0px 0px 6px 0px;
                                            }
                                            & > div
                                            {
                                                display  : block;
                                                position : relative;
                                                width    : 100%;
                                                font-size: 14px;

                                                & b
                                                {
                                                    color:#3a8fff;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                & > .note
                {
                    display       : none;
                    position      : relative;
                    width         : 100%;
                    text-align    : center;
                    color         : #ffffff;
                    text-transform: uppercase;
                    margin        : 40px 0px 0px 0px;

                    & > span
                    {
                        display  : block;
                        position : relative;
                        width    : 100%;
                        font-size: 12px;
                        margin   : 0px 0px 6px 0px;
                    }
                    & > div
                    {
                        display  : block;
                        position : relative;
                        width    : 100%;
                        font-size: 14px;

                        & b
                        {
                            color:#3a8fff;
                        }
                    }
                }
            }
        }
    }
}
.swiper-slide.auto
{
    width: auto !important;
}
.swiper-pagination-bullet
{
    background:rgba(255,255,255,0.6);

    &.swiper-pagination-bullet-active
    {
        background:#56b0e1;
    }
}
.clear-row
{
    display : block;
    position: relative;
    width   : 100%;
    overflow: hidden;
    float   : none;
    clear   : both;
}

.contentheader
{
    display            : block;
    position           : relative;
    width              : 100%;
    box-sizing         : border-box;
    -webkit-box-sizing : border-box;
    padding            : 0px 20px;
    background-position: 50% 50% !important;
    background-size    : cover !important;
    background-repeat  : no-repeat !important;

    & > .backdrop
    {
        display                : block;
        position               : absolute;
        width                  : 100%;
        height                 : 100%;
        top                    : 0;
        left                   : 0;
        backdrop-filter        : blur(10px);
        -webkit-backdrop-filter: blur(10px);
        background             : rgba(0,0,0,0.2);
    }
    & > .headercover
    {
        display           : block;
        position          : relative;
        width             : 100%;
        max-width         : 1100px;
        margin            : 0 auto;
        box-sizing        : border-box;
        -webkit-box-sizing: border-box;
        padding           : 80px 0px 100px 0px;

        & > .text
        {
            display  : block;
            position : relative;
            width    : 100%;
            max-width: 500px;

            & > .title
            {
                display    : block;
                position   : relative;
                width      : 100%;
                font-size  : 36px;
                line-height: 48px;
                font-weight: 800;
                color      : #ffffff;
                margin     : 0px 0px 10px 0px;
            }
            & > .desc
            {
                display    : block;
                position   : relative;
                width      : 100%;
                font-size  : 16px;
                line-height: 26px;
                color      : #b4b4b4;
            }
        }
    }
}

@keyframes selectpop {
    from {transform:scale(0.8); -webkit-transform:scale(0.8)}
    to   {transform:scale(1); -webkit-transform:scale(1)}
}
@-webkit-keyframes selectpop {
    from {transform:scale(0.8); -webkit-transform:scale(0.8)}
    to   {transform:scale(1); -webkit-transform:scale(1)}
}



@media only screen and (max-width: 1180px) {

    body
    {
        & > .wpt-container
        {
            & > .wpt-download
            {
                & > .downloadcover
                {
                    & > .summary
                    {
                        & > img
                        {
                            max-width: 580px;
                            width    : 100%;
                            margin   : 0 auto;
                            float    : none;
                        }
                        & > .text
                        {
                            width   : 500px;
                            position: relative;
                            top     : auto;
                            right   : auto;
                            margin  : 0 auto;
                            top     : -20px;

                            & > .textcover
                            {
                                border-radius        : 10px;
                                -webkit-border-radius: 10px;

                                & > .button
                                {
                                    & > .buttoncover
                                    {
                                        & > .btn
                                        {
                                            & > .refferal
                                            {
                                                display:none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & > .note
                    {
                        display:block;
                    }
                }
            }
        }
    }

}
@media only screen and (max-width: 980px) {
    body
    {
        & > .wpt-container
        {
            & > .wpt-download
            {
                & > .downloadcover
                {
                    & > h1
                    {
                        font-size  : 28px;
                        line-height: 34px;
                        margin     : 0px 0px 8px 0px;
                    }
                    & > h2
                    {
                        font-size  : 14px;
                        line-height: 20px;
                        margin     : 0px 0px 20px 0px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 680px) {
    body
    {
        & > .wpt-container
        {
            & > .wpt-social
            {
                padding   : 0px;
                background: #000000;

                & > .socialcover
                {
                    & > .box
                    {
                        display              : block;
                        position             : relative;
                        top                  : auto;
                        transform            : none;
                        -webkit-transform    : none;
                        border-radius        : 0px;
                        -webkit-border-radius: 0px;
                        padding              : 60px 20px;

                        & > .text
                        {
                            width     : 100%;
                            text-align: center;
                            margin    : 0px 0px 30px 0px;

                            & > div
                            {
                                display       : block;
                                position      : relative;
                                width         : 100%;
                                font-size     : 28px;
                                line-height   : 34px;
                                color         : #ffffff;
                                text-transform: uppercase;
                                margin        : 0px 0px 4px 0px;

                                & > b
                                {
                                    font-weight: 800;
                                }
                            }
                            & > span
                            {
                                display    : block;
                                position   : relative;
                                width      : 100%;
                                font-size  : 14px;
                                line-height: 20px;
                                color      : #ffffff;
                            }
                        }
                        & > .btn
                        {
                            margin:0px;

                            & a
                            {
                                margin : 0px 14px 0px 0px;
                                opacity: 1;

                                & > i
                                {
                                    font-size:28px;
                                }
                            }
                        }
                    }
                }
            }
            & > .wpt-download
            {
                padding:60px 20px;

                & > .downloadcover
                {
                    & > .summary
                    {
                        & > img
                        {
                            max-width:400px;
                        }
                        & > .text
                        {
                            top:-50px;
                            width:100%;
                            max-width:500px;

                            & > .textcover
                            {
                                padding:80px 50px;

                                & .sbox
                                {
                                    padding:0px;

                                    & > i
                                    {
                                        position   : relative;
                                        top        : auto;
                                        left       : auto;
                                        margin     : 0 auto;
                                        width      : 40px;
                                        line-height: 40px;
                                        height     : 40px;
                                        font-size  : 40px;
                                    }
                                    & > div
                                    {
                                        margin:16px 0px 0px 0px;
                                        text-align:center;
                                    }
                                }
                                & > .button
                                {
                                    height: 44px;
                                    bottom: -22px;

                                    & > .buttoncover
                                    {
                                        width:236px;
                                        height:44px;

                                        & > img
                                        {
                                            display:none;
                                        }
                                        & > .btn
                                        {
                                            width  : 100%;
                                            padding: 0px 10px 0px 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & > .note
                    {
                        margin:0px;
                    }
                }
            }
        }
    }
}