@font-face {
  font-family: 'iconmoon';
  src:  url('../fonts/iconmoon.eot?cs3ad3');
  src:  url('../fonts/iconmoon.eot?cs3ad3#iefix') format('embedded-opentype'),
        url('../fonts/iconmoon.ttf?cs3ad3') format('truetype'),
        url('../fonts/iconmoon.woff?cs3ad3') format('woff'),
        url('../fonts/iconmoon.svg?cs3ad3#iconmoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconmoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
  content: "\e921";
}
.icon-bagpack:before {
  content: "\e922";
}
.icon-logout:before {
  content: "\e923";
}
.icon-otp:before {
  content: "\e91e";
}
.icon-country:before {
  content: "\e91f";
}
.icon-phone:before {
  content: "\e920";
}
.icon-password:before {
  content: "\e91b";
}
.icon-email:before {
  content: "\e91c";
}
.icon-user:before {
  content: "\e91d";
}
.icon-mission:before {
  content: "\e918";
}
.icon-watched:before {
  content: "\e919";
}
.icon-check:before {
  content: "\e91a";
}
.icon-arrow-up:before {
  content: "\e917";
}
.icon-close:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e914";
}
.icon-whatsapp:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e903";
}
.icon-instagram1:before {
  content: "\e904";
}
.icon-telegram:before {
  content: "\e905";
}
.icon-volume-high:before {
  content: "\e908";
}
.icon-volume-low:before {
  content: "\e909";
}
.icon-volume-off:before {
  content: "\e90a";
}
.icon-share:before {
  content: "\e90b";
}
.icon-download-3:before {
  content: "\e90c";
}
.icon-download-2:before {
  content: "\e90d";
}
.icon-download-1:before {
  content: "\e90e";
}
.icon-pause:before {
  content: "\e90f";
}
.icon-replay:before {
  content: "\e910";
}
.icon-play:before {
  content: "\e911";
}
.icon-fullscreen:before {
  content: "\e912";
}
.icon-cam:before {
  content: "\e913";
}
.icon-ios:before {
  content: "\e900";
}
.icon-android:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e907";
}
